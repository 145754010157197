var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('cyui-field-record-info', {
    staticClass: "cyui-clean-item",
    attrs: {
      "data": _vm.detailInfo,
      "header-extra-field": _vm.headerExtraField,
      "footer-content-field": _vm.footerContentField,
      "footer-extra-field": _vm.footerExtraField,
      "body-fields": _vm.bodyFields
    },
    on: {
      "go-detail": _vm.goDetail
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };