
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import filters from '@filters/filters'
  import { CleanListItem } from './components'
  import { getListAPI, getPageOfManager } from '@api/maintenance'

  const categoryListData = [
    {
      id: '',
      label: '全部',
      number: 0,
      children: []
    },
    // {
    //   id: '-2',
    //   label: '待支付',
    //   number: 0,
    //   children: []
    // },
    {
      id: '-1',
      label: '进行中',
      number: 0,
      children: []
    },
    {
      id: '4',
      label: '已完成',
      number: 0,
      children: []
    }
    /* {
      id: '3',
      label: '已完成',
      number: 0,
      children: []
    } */
  ]

  export default {
    name: 'CleanList',
    mixins: [common, filters, scrollList, getPageList],
    components: {
      CleanListItem
    },
    data() {
      return {
        headerTitle: '保洁记录',
        categoryInfo: { // 分类数据
          value: '', //
          listData: categoryListData
        },
        showQuestion: false, // 保洁类型弹窗
        cleanTypeList: [], // 保洁类型列表
        initParams: { // 初始化参数
          page: 1,
          size: 20
        },
        searchKey: {} // 搜索参数
      }
    },
    props: {
      status: {
        type: [Number, String],
        default: ''
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      // 进入详情
      goDetail(data) {
        let { id } = data
        this.$router.push({
          name: 'CleanDetail',
          query: {
            id
          }
        })
      },
      handleClick_goClean() {
        this.$router.push({
          name: 'CleanSend'
        })
      },
      handleTabChange({ name }) {
        if (name !== this.status) {
          this.$router.replace({
            name: 'CleanList',
            query: {
              status: name
            }
          })
        }
      },
      questionChange(e) {
        this.searchKey.questionCategoryId = e.value
        this.searchKey.questionCategoryName = e.text
        this.showQuestion = false
        this.resetParams()
        this.getListData()
      },
      onShowQuestion() {
        this.showQuestion = true
        let { projectId } = this.projectInfo
        getPageOfManager({ projectId }).then(res => {
          if (res.data.code === 200) {
            let listData = [{ value: '', text: '全部' }, ...res.data.result.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })]
            this.cleanTypeList = listData
          }
        })
      },
      async getListData({
                          isPullDown,
                          isShowLoading
                        } = {
        isPullDown: false,
        isShowLoading: true
      }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            status,
            listData
          } = this
          let {
            page,
            size,
            questionCategoryName,
            questionCategoryId
          } = this.searchKey
          let { projectId } = this.projectInfo
          let response = await getListAPI({
            pageNum: page,
            pageSize: size,
            type: 2,
            projectId: projectId,
            status,
            questionCategoryName: questionCategoryId ? questionCategoryName : '',
            questionCategoryId
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` == 200) { // 请求成功
            let resultData = result.data || []
            // let hasMore = resultData.length > 0
            let hasMore = page < result.pages
            this.listData = !isPullDown
              ? [...listData, ...resultData]
              : [...resultData]
            if (!hasMore) {
              this.isLastPage = true
              // this.$toast('没有更多数据了！')
            }
            await this.handleCallback({
              isError: false,
              hasMore
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.resetParams()
        await this.getListData()
      }
    },
    filters: {},
    watch: {
      initParams: {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
            this.searchKey = JSON.parse(JSON.stringify(newVal))
          }
        },
        deep: true,
        immediate: true
      },
      status: {
        handler: function(newVal, oldVal) {
          this.searchKey = JSON.parse(JSON.stringify(this.initParams))
          this.searchKey.questionCategoryId = ''
          this.searchKey.questionCategoryName = '全部'
          this.categoryInfo.value = newVal
          this.initData()
        },
        immediate: true
      }
    },
    created() {
      const { token, projectId, dcStewardId, nickName, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { projectId, dcStewardId, nickName, userId, userName })
        this.$store.commit('user/SET_PROJECT_INFO', { projectId })
      }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
