import filters from '@filters/filters';
import { dateFilter } from '@utils/utils';
import { statusMap } from '@/dictionaries/clean';
export default {
  name: 'CleanListItem',
  mixins: [filters],
  components: {},
  data() {
    return {
      headerExtraField: {
        isShow: true,
        title: '预约时间：',
        key: 'expectRepairDate',
        filterMethod: dateFilter
      },
      footerContentField: {
        isShow: true,
        title: '保洁进度'
      },
      footerExtraField: {
        isShow: true,
        title: '',
        key: 'status',
        key2: 'payStatus',
        filterMethod: (data, data2) => {
          if (data == '4') {
            return '已完成';
          } else if (data == '1' && data2 == '0') {
            return '去支付';
          } else {
            return '进行中';
          }
          // return statusMap[data]['text']
        },

        getColorMethod: data => {
          // console.log(data, '22')
          return statusMap[data]['colorType'];
        }
      },
      bodyFields: [{
        title: '房号：',
        key: 'spaceName'
      }, {
        title: '姓名：',
        key: 'contactsName'
      }, {
        title: '联系方式：',
        key: 'contactsMobile'
      }, {
        title: '保洁类型：',
        key: 'questionCategoryName'
      }, {
        title: '期望上门时间：',
        key: 'expectRepairDate',
        filterMethod: dateFilter
      }]
    };
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    }
  },
  computed: {},
  methods: {
    goDetail() {
      this.$emit('go-detail', this.detailInfo);
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};